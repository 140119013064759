/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong",
    em: "em"
  }, _provideComponents(), props.components), {SideBySide} = _components;
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "join-the-1-network-of-hearing-care-professionals-in-canada",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#join-the-1-network-of-hearing-care-professionals-in-canada",
    "aria-label": "join the 1 network of hearing care professionals in canada permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Join the #1 Network of Hearing Care Professionals in Canada."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✔ Make use of the power of the internet"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✔ Keep your independence while leaving your competition behind"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✔ Become a member of the #1 hearing care network for FREE"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Your customers are online. Are you?")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The hearing aid industry continues to experience exponential growth. Two factors contributing to this growth are: (1) big box stores like Costco have entered the market, and (2) people are actively searching for hearing aids online. In order for your practice to be even more successful, you need to keep up with the big players in the industry and take full advantage of the power of the Internet."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our innovative marketing approach attracts over 85,000 visitors to our website daily, making us the biggest online hearing aid provider in the world! Our marketing strategy allows us to unlock the potential of the Internet for independent audiologists and hearing specialists. It’s absolutely FREE to become part of one of the fastest growing networks of hearing care providers in Canada.\nIn addition to providing you with new customers, we will make you a part of one of the fastest growing networks of experienced and caring professionals. We are ready to help you through any challenges you may face and support you whenever you need it."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Once you sign up with us, we will assign one of our partner managers to work directly with you. They will guide you through our process and answer all your questions. Thanks to our exclusive service, you can dedicate your time and energy to helping your customers. With hear.com, you won’t have to worry about all the costs typically associated with website management, servers, advertisement partnerships, and marketing teams. We will take care of everything for you!"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-makes-hearcom-customers-unique",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-makes-hearcom-customers-unique",
    "aria-label": "what makes hearcom customers unique permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What Makes Hear.Com Customers Unique?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our experienced marketing team of 50+ professionals focuses on targeting people who are searching the web for hearing aids. This typically draws in younger, first-time users who would rather get help online before going to your office. Once we have a customer on the phone, one of our 200+ hearing experts pre-qualifies the client and ensures that by our comprehensive consultation every customer is well-informed before visiting your practice."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "to-summarize-our-customers-are",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#to-summarize-our-customers-are",
    "aria-label": "to summarize our customers are permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "To Summarize, Our Customers Are:"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/how-it-works-step-2.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "✔ Younger and usually first-time users"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "✔ Eager to find a hearing loss solution"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "✔ Well-informed"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "✔ Ready for their appointment with you")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "join-the-1-hearing-care-network-in-canada-today",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#join-the-1-hearing-care-network-in-canada-today",
    "aria-label": "join the 1 hearing care network in canada today permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Join the #1 Hearing Care Network in Canada Today!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "“We are constantly adding new partners to our existing base of 200 practices. Since we reserve specific regions exclusively for each and every partner, spots are limited. Sign up here to check for availability in your area.”")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Sylvie Perrier,"), " Partner Manager. Phone: +1 (416) 859-8995, e-mail: ", React.createElement(_components.a, {
    href: "mailto:sylvie.perrier@hear.com",
    className: "c-md-a"
  }, "sylvie.perrier@hear.com.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
